import highlight from "../images/others/highlight-title.svg";
import Typewriter from "typewriter-effect";
import tab from "../images/others/tab.svg";
import tel from "../images/others/tel.svg";
import pc from "../images/others/pc.svg";
import mr from "../images/logos/mouse-r.png";
import mb from "../images/logos/mouse-b.png";
import "../styles/heroInside.css";
import { useEffect, useRef, useState } from "react";

export const HeroInside = ({ scrollToSection }) => {
  const [statusTyper, setStatusTyper] = useState(false);
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.3 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <div ref={sectionRef} className="inside flex row center">
      <div className="slogan flex colm">
      <div className="slogan-title flex colm center">
            <p className="letter-spacing">
              Des solutions{" "}
              <span className="letter-spacing">
                Digitales <img src={highlight} alt="#" />
              </span>
            </p>
            <p className={statusTyper === true ? "hide-typer" : ""}>
              <Typewriter
                onInit={(typewriter) => {
                  typewriter
                    .typeString("sur mesure pour vous.")
                    .pauseFor(2500)
                    .deleteChars(5)
                    .pauseFor(500)
                    .typeString("votre entreprise.")
                    .callFunction(() => {
                      setTimeout(() => {
                        setStatusTyper(true);
                      }, 150);
                    })
                    .start();
                }}
              />
            </p>
          </div>
        {/* <div className="remplacement-slogan-title">
          
        </div> */}
        <div className="second-slo">
          <p>
            Chez Impact Plus Digital, nous sommes des experts en matière de
            création et d'optimisation de solutions digitales pour les
            entreprises de toutes tailles.
          </p>
        </div>
        <button onClick={() => scrollToSection("contacts-us")}>
          Commencez votre transformation
        </button>
      </div>
      <div className="illustration">
        <div className="tel-contain">
          <div className="tel">
            <div className="pouce"></div>
            <div className="contains-panel-swipe">
              <div className="panel-swipe row">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <img src={tel} alt="tel" />
          </div>
        </div>
        <div className="tab-contain">
          <div className="tab">
            <div className="bar-filled"></div>
            <div className="second-bar-filled"></div>
            <div className="contains-top-anim">
              <div className="anim-contain">
                <div></div>
                <div></div>
              </div>
            </div>
            <img src={tab} alt="tab" />
          </div>
        </div>
        <div className="pc-contain">
          <div className="pc">
            <div></div>
            <div></div>
            <div></div>
            <img src={pc} alt="pc" />
          </div>
        </div>
        <div className="btn-comol">
          <div className="contains">
            <button>Site web</button>
            <button>Applications mobiles</button>
            <img src={mr} alt="" />
            <img src={mb} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
