import "../styles/contactUs.css";
import icon from "../images/logos/icon.svg";
export const ContactUs = ({ setContactUs }) => {
  return (
    <section id="contacts-us" className=" contact-us flex colm center">
      <img className="icon-digital icon-digital-contact" src={icon} alt=""/>
      {/* eslint-disable-next-line */}
      <div className="contains max-width flex colm center">
        <div className="max-width container flex center">
          <div className=" title flex colm center">
            <h3 className="letter-spacing">
              Vous avez un projet en tête? Mettons-nous au travail
            </h3>
            <p>
              Que vous soyez intéressé par nos services ou par une solution
              personnalisée, nous sommes là pour vous aider à trouver le plan
              qui répond à vos besoins.
            </p>
            <button onClick={()=>setContactUs(true)}>Contactez nous</button>
          </div>
        </div>
      </div>
    </section>
  );
};
