export const SmsLogo = ({ color = "#ffff" }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1667 17.5834H5.83341C3.33341 17.5834 1.66675 16.3334 1.66675 13.4167V7.58341C1.66675 4.66675 3.33341 3.41675 5.83341 3.41675H14.1667C16.6667 3.41675 18.3334 4.66675 18.3334 7.58341V13.4167C18.3334 16.3334 16.6667 17.5834 14.1667 17.5834Z"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.1666 8L11.5582 10.0833C10.6999 10.7667 9.29158 10.7667 8.43325 10.0833L5.83325 8"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export const CallLogo = ({ color = "#ffff" }) => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3084 15.7751C18.3084 16.0751 18.2417 16.3834 18.1001 16.6834C17.9584 16.9834 17.7751 17.2667 17.5334 17.5334C17.1251 17.9834 16.6751 18.3084 16.1667 18.5167C15.6667 18.7251 15.1251 18.8334 14.5417 18.8334C13.6917 18.8334 12.7834 18.6334 11.8251 18.2251C10.8667 17.8167 9.90842 17.2667 8.95842 16.5751C8.00008 15.8751 7.09175 15.1001 6.22508 14.2417C5.36675 13.3751 4.59175 12.4667 3.90008 11.5167C3.21675 10.5667 2.66675 9.61675 2.26675 8.67508C1.86675 7.72508 1.66675 6.81675 1.66675 5.95008C1.66675 5.38341 1.76675 4.84175 1.96675 4.34175C2.16675 3.83341 2.48341 3.36675 2.92508 2.95008C3.45841 2.42508 4.04175 2.16675 4.65841 2.16675C4.89175 2.16675 5.12508 2.21675 5.33341 2.31675C5.55008 2.41675 5.74175 2.56675 5.89175 2.78341L7.82508 5.50842C7.97508 5.71675 8.08341 5.90841 8.15841 6.09175C8.23341 6.26675 8.27508 6.44175 8.27508 6.60008C8.27508 6.80008 8.21675 7.00008 8.10008 7.19175C7.99175 7.38341 7.83341 7.58341 7.63341 7.78341L7.00008 8.44175C6.90841 8.53341 6.86675 8.64175 6.86675 8.77508C6.86675 8.84175 6.87508 8.90008 6.89175 8.96675C6.91675 9.03341 6.94175 9.08341 6.95842 9.13341C7.10842 9.40841 7.36675 9.76675 7.73341 10.2001C8.10841 10.6334 8.50841 11.0751 8.94175 11.5167C9.39175 11.9584 9.82508 12.3667 10.2667 12.7417C10.7001 13.1084 11.0584 13.3584 11.3417 13.5084C11.3834 13.5251 11.4334 13.5501 11.4917 13.5751C11.5584 13.6001 11.6251 13.6084 11.7001 13.6084C11.8417 13.6084 11.9501 13.5584 12.0417 13.4667L12.6751 12.8417C12.8834 12.6334 13.0834 12.4751 13.2751 12.3751C13.4667 12.2584 13.6584 12.2001 13.8667 12.2001C14.0251 12.2001 14.1917 12.2334 14.3751 12.3084C14.5584 12.3834 14.7501 12.4917 14.9584 12.6334L17.7167 14.5917C17.9334 14.7417 18.0834 14.9167 18.1751 15.1251C18.2584 15.3334 18.3084 15.5417 18.3084 15.7751Z"
        stroke={color}
        stroke-width="1.5"
        stroke-miterlimit="10"
      />
    </svg>
  );
};

export const ProfilLogo = ({ color = "#ffff" }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8445 21.6618C8.15273 21.6618 5 21.0873 5 18.7865C5 16.4858 8.13273 14.3618 11.8445 14.3618C15.5364 14.3618 18.6891 16.4652 18.6891 18.766C18.6891 21.0658 15.5564 21.6618 11.8445 21.6618Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M11.8372 11.1735C14.26 11.1735 16.2236 9.2099 16.2236 6.78718C16.2236 4.36445 14.26 2.3999 11.8372 2.3999C9.41452 2.3999 7.44998 4.36445 7.44998 6.78718C7.4418 9.20172 9.3918 11.1654 11.8063 11.1735C11.8172 11.1735 11.8272 11.1735 11.8372 11.1735Z"
        stroke={color}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};
