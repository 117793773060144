import "../styles/header.css";
import logo from "../images/logos/logo.svg";
import { useState, useEffect, useRef } from "react";
export const Header = ({ setContactUs, scrollToSection }) => {
  const [containerMenuState, setContainerMenuState] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const divRef = useRef(null);

  const ShowMenu = () => {
    setContainerMenuState(!containerMenuState);
  };

  const [diverMarge, setDiverMarge] = useState(86);

  useEffect(() => {
    if (divRef.current) {
      setDiverMarge(divRef.current.clientHeight);
    }
  }, [divRef]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > diverMarge) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    /* eslint-disable-next-line */
  }, []);

  return (
    <>
      <div className="contain-me" style={{ height: diverMarge + "px" }}></div>
      <div
        style={{ top: diverMarge + "px" }}
        className={
          "container-fixed-menu-t flex colm" +
          (containerMenuState === true
            ? " container-fixed-menu-actif"
            : " container-fixed-menu-no-active")
        }
      >
        <button
          onClick={() => {
            scrollToSection("skills");
            setContainerMenuState(false);
          }}
        >
          Compétences
        </button>
        <button
          onClick={() => {
            scrollToSection("works");
            setContainerMenuState(false);
          }}
        >
          Travaux
        </button>
        <button
          onClick={() => {
            scrollToSection("thinks");
            setContainerMenuState(false);
          }}
        >
          Témoignages
        </button>
        <button
          onClick={() => {
            scrollToSection("contacts");
            setContainerMenuState(false);
          }}
        >
          A propos
        </button>
      </div>
      <header
        ref={divRef}
        className={
          "flex row center " +
          (scrolled === true ? " show-me-header " : "") +
          (containerMenuState === true ? " no-background-visible" : " ")
        }
      >
        <div className="contains flex row center max-width">
          <img
            src={logo}
            onClick={() => scrollToSection("top")}
            alt="Impact Plus Digital"
          />
          <nav>
            <ul className="flex row center">
              <li>
                <button
                  onClick={() => {
                    scrollToSection("skills");
                    setContainerMenuState(false);
                  }}
                >
                  Compétences
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollToSection("works");
                    setContainerMenuState(false);
                  }}
                >
                  Travaux
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollToSection("thinks");
                    setContainerMenuState(false);
                  }}
                >
                  Témoignages
                </button>
              </li>
              <li>
                <button
                  onClick={() => {
                    scrollToSection("contacts");
                    setContainerMenuState(false);
                  }}
                >
                  A propos
                </button>
              </li>
            </ul>
          </nav>
          <button onClick={() => ShowMenu()} className="menu-icon flex">
            <div
              className={
                "contains flex colm " +
                (containerMenuState === true && " show-active-cross")
              }
            >
              <div></div>
              <div></div>
              <div></div>
            </div>
          </button>
          <button onClick={() => setContactUs(true)}>Contactez nous</button>
        </div>
      </header>
    </>
  );
};
