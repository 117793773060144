import "../styles/projet.css";
import { ProjetCard } from "./ProjetCard";
import kabaaShopImage from "../images/others/img-kabaashop.png";
import abexpressImage from "../images/others/img-ab-express.png";
import autoPlusImage from "../images/others/img-auto-plus.png";
import icon from "../images/logos/icon.svg";
import arrow from "../images/logos/arrow-right.png";
import { useEffect, useRef } from "react";
export const Projet = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.18 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  let projets = [
    {
      img: kabaaShopImage,
      title: "Kabaa shop",
      link: "#",
      second: "(WEBSITE)",
      content:
        "Kabaa shop est un site qui vous permet de faire tout vos achats de matériels de tout genre en un seul clique.",
      finish: true,
    },
    {
      img: autoPlusImage,
      title: "Auto Plus",
      link: "#",
      second: "(WEBSITE + MOBILE APP)",
      content:
        "Auto plus est un site de vente de véhicules conçu spécialement pour vous afin de vous aider à l’achat de votre véhicule neuf où de seconde main.",
      finish: false,
    },
    {
      img: abexpressImage,
      title: "AB Express",
      link: "#",
      second: "(WEBSITE + MOBILE APP)",
      content:
        "AB Express vous permet d'explorer et d'acheter une vaste gamme de produits alimentaires de qualité, depuis le confort de votre domicile.",
      finish: true,
    },
  ];
  return (
    <section ref={sectionRef} id="works" className="projet flex colm center">
      <img className="icon-digital icon-digital-projet" src={icon} alt="" />
      <div className="title flex colm center">
        <h3 className="letter-spacing">Qu’est-ce que nous faisons ?</h3>
        <p>
          Nous aidons nos clients à passer à l'étape suivante. Constatez le avec
          cette sélection de nos travaux.
        </p>
      </div>
      <div className="max-width projet-contains flex row center">
        {/* eslint-disable-next-line */}
        {projets.map((pr) => {
          return (
            <ProjetCard
              img={pr.img}
              title={pr.title}
              link={pr.link}
              second={pr.second}
              content={pr.content}
              finish={pr.finish}
            />
          );
        })}
      </div>
      <div style={{ display: "none" }} className="btn-see-work flex row">
        <span>Voir tout nos travaux</span>
        <img src={arrow} alt="" />
      </div>
    </section>
  );
};
