import "../styles/thinkCard.css";
export const ThinkCard = ({ img, pack, name, title, info }) => {
  return (
    <div className="card-think flex colm">
      <div className={img !== "" ? "top-info" : "top-info top-info-flex"}>
        {img !== "" && <img src={img} alt="profil" />}
        <h5>{name}</h5>
        <span>{title}</span>
      </div>
      <p>{info}</p>
    </div>
  );
};
