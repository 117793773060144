import { useState } from "react";
import { ContactUs } from "../composants/ContactUs";
import { ContactUsForm } from "../composants/ContactUsForm";
import { Footer } from "../composants/Footer";
import { Hero } from "../composants/Hero";
import { Projet } from "../composants/Projet";
import { Skills } from "../composants/Skills";
import { ThinkAbout } from "../composants/ThinkAbout";
import "../styles/accueil.css";

const Accueil = () => {
  const [contactUs, setContactUs] = useState(false);
  const scrollToSection = (idsection) => {
    const offset = -40;
    const section = document.getElementById(idsection);
    window.scrollTo({
      top: section.offsetTop + offset,
      behavior: "smooth",
    });
  };
  return (
    <>
      <ContactUsForm setContactUs={setContactUs} contactUs={contactUs} />
      <Hero setContactUs={setContactUs} scrollToSection={scrollToSection} />
      <main>
        <Skills />
        <Projet />
        <ThinkAbout />
        <ContactUs setContactUs={setContactUs} />
      </main>
      <Footer scrollToSection={scrollToSection} />
    </>
  );
};

export default Accueil;
