import "../styles/footer.css";
import logoBlue from "../images/logos/logo-blue.svg";
import logoWhite from "../images/logos/logo.svg";
import fb from "../images/others/fb.svg";
import lk from "../images/others/lk.svg";
import ig from "../images/others/ig.svg";
import sms from "../images/others/sms.svg";
import call from "../images/others/call.svg";
import loc from "../images/others/location.svg";
import { SmsLogo } from "../images/others/logo";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { NotifInfo } from "./NotifInfo";
export const Footer = ({ scrollToSection }) => {
  const refBtn = useRef(null);
  const refMail = useRef(null);
  const [addLoading, setAddLoading] = useState(false);
  const [newsNotif, setNewsNotif] = useState(<></>);

  const handleMail = () => {
    if (addLoading === true) {
      return;
    }
    let val = "";
    val = refMail.current.value.toString();
    if (val.length > 5) {
      // eslint-disable-next-line
      var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (val.match(mailformat)) {
        refBtn.current.disabled = false;
      } else {
        refBtn.current.disabled = true;
      }
    } else {
      refBtn.current.disabled = true;
    }
  };

  const sendNewsLetter = async () => {
    refBtn.current.disabled = true;
    setAddLoading(true);
    try {
      const url = "https://api.brevo.com/v3/contacts";

      const headers = {
        accept: "application/json",
        "content-type": "application/json",
        "api-key":
          "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",
      };
      const data = {
        email: refMail.current.value.toString(),
      };

      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data),
      });

      const responseData = await response.json();

      if (response.status === 400) {
        setNewsNotif(
          <NotifInfo
            info={"Votre email fait déjà partie de notre newsletter"}
          />
        );
      } else if (response.status === 201) {
        setNewsNotif(
          <NotifInfo
            info={"Votre email fait a été ajouter à notre newsletter"}
          />
        );
        refMail.current.value = "";
      } else {
        setNewsNotif(<NotifInfo info={"Erreur reseau"} />);
      }
      resetNotif();
    } catch (error) {
      setAddLoading(false);
      setNewsNotif(<></>);
      console.error(error);
    }
  };
  const resetNotif = () => {
    setTimeout(() => {
      setAddLoading(false);
      setNewsNotif(<></>);
    }, 5000);
  };
  useEffect(() => {
    if (addLoading === false) {
      handleMail();
    }
    // eslint-disable-next-line
  }, [addLoading]);
  return (
    <footer id="contacts" className="flex colm">
      <hr className="max-width" />
      <div className="max-width info flex row">
        <img className="logo-mobile" src={logoWhite} alt="logo" />
        <div className="contains-info flex row">
          <img className="logo-mobile" src={logoWhite} alt="logo" />
          <div className="ipnew flex colm">
            <img src={logoBlue} alt="logo" />
            <span>
              Restez informé sur nos nouveaux travaux et nos dernières sorties.
            </span>
            <div className="flex row">
              <div className="input-mail flex row">
                <SmsLogo color={"#172368"} />
                <input
                  onChange={handleMail}
                  ref={refMail}
                  type="email"
                  placeholder="Adresse mail"
                />
              </div>
              <button
                className={addLoading === true && "loader"}
                ref={refBtn}
                onClick={sendNewsLetter}
              >
                Envoyer
              </button>
            </div>
          </div>
        </div>
        <div className="options-nav flex row">
          <div className="nav flex colm">
            <h3>Navigation</h3>
            <ul className="flex colm">
              <li onClick={() => scrollToSection("skills")}>Compétences</li>
              <li onClick={() => scrollToSection("works")}>Travaux</li>
              <li onClick={() => scrollToSection("thinks")}>Témoignages</li>
              <li onClick={() => scrollToSection("contacts")}>A propros</li>
            </ul>
          </div>
          <div className="nav flex colm">
            <h3>Sorties récentes</h3>
            <ul className="flex colm">
              <li>
                <Link to={"#"} target="_blank">
                  Kabaashop
                </Link>
              </li>
              <li>
                <Link to={"#"} target="_blank">
                  Ma voiture
                </Link>
              </li>
              <li>
                <Link to={"#"} target="_blank">
                  Auto plus
                </Link>
              </li>
            </ul>
          </div>
          <div className="nav flex colm">
            <h3>Suivez nous {window.innerWidth <= 635 && " :"}</h3>
            <ul className="social flex row">
              {/* <li>
                <Link to={"#"} target="_blank">
                  <img src={fb} alt="fb" />
                </Link>
              </li> */}
              <li style={{ alignItems: "center" }} className="flex row">
                <Link
                  style={{ alignItems: "flex-end", columnGap: "15px" }}
                  className="flex row"
                  to={"https://www.linkedin.com/company/impact-plus-digital/"}
                  target="_blank"
                >
                  <img src={lk} alt="lk" />

                  <span style={{height:"18px"}}>Linkedin</span>
                </Link>
              </li>
              {/* <li>
                <Link to={"#"} target="_blank">
                  <img src={ig} alt="ig" />
                </Link>
              </li> */}
            </ul>
            <ul className="flex colm contact-block">
              <li className="flex row center">
                <Link
                  className="flex row center"
                  to={"mailto:impactplus19@gmail.com"}
                >
                  <img style={{ marginRight: "15px" }} src={sms} alt="" />{" "}
                  impactplus19@gmail.com
                </Link>
              </li>
              <li className="contact-num flex row center">
                <img style={{ marginRight: "15px" }} src={call} alt="" />
                <span className="flex colm">
                  <p>
                    <Link className="flex row center" to={"#"}>
                      +225 07 77 19 70 59
                    </Link>
                  </p>
                  <p>
                    <Link className="flex row center" to={"#"}>
                      +225 07 09 66 43 00
                    </Link>
                  </p>
                </span>
              </li>
              <li className="flex row center">
                <Link
                  className="flex row center"
                  to={
                    "https://www.google.com/maps/search/?api=1&query=5.334847%2C-3.998295"
                  }
                  target="_blank"
                >
                  <img style={{ marginRight: "15px" }} src={loc} alt="" />
                  Nouvelle pharmacie sainte marie, Cocody, Abidjan - CI
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyr flex colm center">
        <hr className="max-width" />
        <span className="max-width">
          © 2023 Impact Plus Digital. Tous droits réservés.
        </span>
      </div>
      {newsNotif}
    </footer>
  );
};
