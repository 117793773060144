import "../styles/thinkAbout.css";
import photo from "../images/others/profil-img.png";
import { ThinkCard } from "./ThinkCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper";
import { useEffect, useRef, useState } from "react";

export const ThinkAbout = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.18 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);

  const [pageWidth, setPageWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  let commentaire1 = [
    {
      img: "",
      name: "Patrick Eloïme",
      title: "Client",
      message:
        "Félicitations pour votre site internet ! La conception est superbe et captivante, avec des visuels attrayants qui attirent immédiatement l'attention.",
    },
    {
      img: "",
      name: "Victoire koffi",
      title: "Cliente",
      message:
        "Les fonctionnalités interactives ajoutent une dimension ludique à l'expérience utilisateur. Merci pour votre travail bien fait",
    },
    {
      img: "",
      name: "Karine Koutoua",
      title: "Cliente",
      message:
        "J'apprécie la clarté et la concision de vos informations, ainsi que la manière dont vous présentez les détails de manière accessible.",
    },
    {
      img: "",
      name: "Christelle Amon",
      title: "Cliente",
      message:
        "Votre site est une véritable mine d'or d'informations pertinentes et intéressantes. Continuez ainsi, c'est un réel plaisir de parcourir votre site !",
    },
    {
      img: "",
      name: "Trésor Vanga",
      title: "Client",
      message:
        "Je le recommande vivement à tous ceux qui recherchent des ressources de qualité dans ce domaine. Bravo à toute l'équipe pour leur excellent travail !",
    },
  ];
  let commentaire2 = [
    {
      img: "",
      name: "Richard Vodou",
      title: "Client",
      message:
        "La mise en page est attrayante et facile à naviguer. C'est vraiment un plaisir de parcourir ce site et d'y trouver des informations utiles.",
    },
    {
      img: "",
      name: "Diabate ismailhe",
      title: "Fondateur AutoPlus",
      message:
        "J'ai été satisfait pour la conception de mon Site Internet de vente de pièces de véhicules. Beau travail !",
    },
    {
      img: "",
      name: "Jonathan Moné",
      title: "Client",
      message:
        "Je suis vraiment impressionné par la qualité du contenu de ce site web ! Les articles sont bien écrits, informatifs et très pertinents.",
    },
    {
      img: "",
      name: "Mikalo Christian",
      title: "Client",
      message:
        "Franchement c’est génial, aucun bug",
    },
    {
      img: "",
      name: "Sanogo Mohammed",
      title: "Client",
      message:
        "Cette application est superbe c’est ce qu’il me fallait",
    },
  ];

  return (
    <section ref={sectionRef} id="thinks" className="work flex colm center">
      <div className="title flex colm center">
        <h3 className="letter-spacing">Ce qu'ils pensent de nous</h3>
        <p>
          Découvrez ce que nos clients ont à dire sur leur expérience avec
          Impact Plus Digital.
        </p>
      </div>
      <div className="max-width-msg contains-card felx center">
        {pageWidth <= 635 && (
          <Swiper
            rewind={true}
            slidesPerView={"auto"}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            spaceBetween={8}
            pagination={{
              clickable: true,
            }}
            modules={[Autoplay, Pagination]}
            className="message-swip"
          >
            {commentaire1.concat(commentaire2).map((el) => {
              return (
                <div className="image-group">
                  <SwiperSlide>
                    <ThinkCard
                      img={el.img}
                      name={el.name}
                      title={el.title}
                      info={el.message}
                    />
                  </SwiperSlide>
                </div>
              );
            })}
          </Swiper>
        )}

        {pageWidth > 635 && (
          <div className="wrapper flex center colm">
            <div className="marquee flex">
              <div className="marquee-group flex row">
                {commentaire1.map((el) => {
                  return (
                    <div className="image-group">
                      <ThinkCard
                        img={el.img}
                        name={el.name}
                        title={el.title}
                        info={el.message}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="marquee-group flex row">
                {commentaire1.map((el) => {
                  return (
                    <div className="image-group">
                      <ThinkCard
                        img={el.img}
                        name={el.name}
                        title={el.title}
                        info={el.message}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="marquee r-anim flex">
              <div className="marquee-group flex row">
                {commentaire2.map((el) => {
                  return (
                    <div className="image-group">
                      <ThinkCard
                        img={el.img}
                        name={el.name}
                        title={el.title}
                        info={el.message}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="marquee-group flex row">
                {commentaire2.map((el) => {
                  return (
                    <div className="image-group">
                      <ThinkCard
                        img={el.img}
                        name={el.name}
                        title={el.title}
                        info={el.message}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};
