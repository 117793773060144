import "../styles/skills.css";
import call from "../images/others/monitor.svg";
import mobile from "../images/others/mobile.svg";
import path from "../images/others/path.svg";
import cube from "../images/others/3dcube.svg";
import game from "../images/others/game.svg";
import icon from "../images/logos/icon.svg";
import { SkillCard } from "./SkillCard";
import { useEffect, useRef } from "react";
export const Skills = () => {
  const sectionRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && entry.intersectionRatio > 0) {
            entry.target.classList.add("visible");
          }
        });
      },
      { threshold: 0.25 }
    );
    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }
    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  return (
    <section ref={sectionRef} id="skills" className="skills flex colm center">
      <div className="title flex colm center">
        <h3 className="letter-spacing">Ce que nous proposons</h3>
        <p>
          Nous offrons une gamme complète de services, allant de la conception
          de sites web et d'applications mobiles à l'optimisation de la présence
          en ligne et au marketing numérique.
        </p>
      </div>
      <img className="icon-digital icon-digital-skills" src={icon} alt="" />
      <div className="skills-projet flex colm">
        <div className="do-skills flex row">
          <SkillCard
            img={call}
            number={"01"}
            color={"var(--secondary-500)"}
            title={"Développement web"}
            secondTitle={
              "Nous concevons et développons vos sites et applications web."
            }
          />
          <SkillCard
            img={mobile}
            number={"02"}
            color={"var(--warning-500)"}
            title={"Développement Mobile"}
            secondTitle={
              "Nous concevons et développons vos applications mobiles."
            }
          />
          <SkillCard
            img={path}
            number={"03"}
            color={"var(--succes-300)"}
            title={"Design"}
            secondTitle={
              "Nous développons vos idées de produits en harmonie avec les normes du marché."
            }
          />
        </div>

        <div className="do-skills flex row">
          <SkillCard
            img={path}
            number={"03"}
            color={"var(--succes-300)"}
            title={"Design"}
            secondTitle={
              "Nous développons vos idées de produits en harmonie avec les normes du marché."
            }
          />
          <SkillCard
            img={cube}
            number={"04"}
            color={"var(--secondary-500)"}
            title={"Design 3d"}
            secondTitle={"Modélisation et animation 3d."}
          />
          <SkillCard
            img={game}
            number={"05"}
            color={"var(--warning-500)"}
            title={"Développement de jeux"}
            secondTitle={"Conception et réalisation de jeux 2d et 3d."}
          />
        </div>
      </div>
    </section>
  );
};
