import { SmsLogo, CallLogo, ProfilLogo } from "../images/others/logo";
import logo from "../images/logos/logo.svg";
import "../styles/contactUsForm.css";
import { NotifInfo } from "./NotifInfo";
import emailjs from "@emailjs/browser";
import { useEffect, useRef, useState } from "react";
export const ContactUsForm = ({ contactUs, setContactUs }) => {
  const refSec = useRef(null);
  const refBtn = useRef(null);
  const refMail = useRef(null);
  const refNum = useRef(null);
  const refName = useRef(null);
  const refText = useRef(null);
  const [emailLoading, setEmailLoading] = useState(false);
  const [newsNotif, setNewsNotif] = useState(<></>);

  const handleInfo = () => {
    if (emailLoading === true) {
      return;
    }
    let valMail = "";
    let valNum = "";
    let valName = "";
    let valText = "";
    valMail = refMail.current.value.toString();
    valNum = refNum.current.value.toString();
    valName = refName.current.value.toString();
    valText = refText.current.value.toString();
    // eslint-disable-next-line
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (
      valMail.match(mailformat) &&
      valNum.length >= 8 &&
      valName.length >= 5 &&
      valText.length >= 10
    ) {
      refBtn.current.disabled = false;
    } else {
      refBtn.current.disabled = true;
    }
  };

  const sendMail = (event) => {
    refBtn.current.disabled = true;
    setEmailLoading(true);

    const information = {
      email: refMail.current.value.toString(),
      numero: refNum.current.value.toString(),
      name: refName.current.value.toString(),
      message: refText.current.value.toString(),
    };
    emailjs
      .send(
        "service_uebzr2p",
        "template_k8zapvy",
        information,
        "7Vl7rI47p8A5uLOfS"
      )
      .then(
        (_result) => {
          setNewsNotif(<NotifInfo info={"Nous avous réçu votre message"} />);
          resetForm();
          setTimeout(() => {
            refBtn.current.disabled = false;
            setEmailLoading(false);
            setContactUs(false);
            setNewsNotif(<></>);
          }, 5000);
        },
        (_error) => {
          setNewsNotif(<NotifInfo info={"Erreur reseau"} />);
          setEmailLoading(true);
          setTimeout(() => {
            refBtn.current.disabled = false;
            setEmailLoading(false);
            setContactUs(false);
            setNewsNotif(<></>);
          }, 5000);
        }
      );
    event.stopPropagation();
  };

  const resetForm = () => {
    refMail.current.value = "";
    refName.current.value = "";
    refNum.current.value = "";
    refText.current.value = "";
  };
  const goOutMenu = (event) => {
    event.stopPropagation();
    setContactUs(false);
  };

  useEffect(() => {
    if (emailLoading === false) {
      handleInfo();
    }
    // eslint-disable-next-line
  }, [emailLoading]);
  return (
    <>
      <div
        ref={refSec}
        className={
          "contact-form transition flex colm center " +
          (contactUs === true && " show-me-contact")
        }
      >
        <img src={logo} alt="Impact Plus Digital" />
        <div className="contains flex colm center">
          <div className="title flex colm center">
            <h3>Contactez nous</h3>
            <span>
              Renseignez le formulaire et expliquez nous votre projet dans les
              grandes lignes.
            </span>
          </div>
          <button
            onClick={(event) => goOutMenu(event)}
            className="flex row center"
          >
            <div className="contains-btn">
              <div></div>
              <div></div>
            </div>
            <span>fermer</span>
          </button>
          <div className="flex row center input-contact">
            <SmsLogo color={"#172368"} />
            <input
              ref={refMail}
              onChange={handleInfo}
              type="email"
              placeholder="Entrez votre email"
            />
          </div>
          <div className="flex row center input-contact">
            <CallLogo color={"#172368"} />
            <input
              ref={refNum}
              onChange={handleInfo}
              type="text"
              placeholder="Votre numero précédé du +xxx"
            />
          </div>
          <div className="flex row center input-contact">
            <ProfilLogo color={"#172368"} />
            <input
              ref={refName}
              onChange={handleInfo}
              type="text"
              placeholder="Entrez votre nom et prénoms"
            />
          </div>
          <div className="flex row input-contact">
            <textarea
              ref={refText}
              onChange={handleInfo}
              placeholder="Entrez votre message"
            />
          </div>
          <button
            className={emailLoading === true && "loader"}
            ref={refBtn}
            onClick={(event) => sendMail(event)}
          >
            Envoyer
          </button>
        </div>
        {newsNotif}
      </div>
    </>
  );
};
