import "../styles/hero.css";
import { Header } from "../composants/Header";
import { HeroInside } from "../composants/HeroInside";

export const Hero = ({setContactUs, scrollToSection}) => {
  return (
    <section id="top" className="hero">
      <Header setContactUs={setContactUs} scrollToSection={scrollToSection}/>
      <HeroInside scrollToSection={scrollToSection}/>
    </section>
  );
};
