import { Link } from "react-router-dom";
import "../styles/projetCard.css";

export const ProjetCard = ({ img, title, link, second, content, finish }) => {
  return (
    <Link to={link} className={finish ===false ? "projet-card flex colm finish-card" : "projet-card flex colm"}>
      <div className="top">
        <img src={img} alt={img + title} />
      </div>
      {finish === false && (
        <div className="finish-div">
          <span>En cours</span>
        </div>
      )}
      <div className="bottom flex colm">
        <div className="title-btm">
          <h3>{title}</h3>
          <span>{second}</span>
        </div>
        <p>{content}</p>
      </div>
    </Link>
  );
};
