import "../styles/notifInfo.css";
import icon from "../images/logos/icon.svg";
export const NotifInfo = ({info}) => {
  return (
    <div className="notif">
      <div className="contains">
        <img src={icon} alt="" />
        <span>{info}</span>
      </div>
    </div>
  );
};
