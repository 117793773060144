import "../styles/skillCard.css";

export const SkillCard = ({ number, img, color, title, secondTitle }) => {
  return (
    <div className="flex colm">
      <div className="top flex colm">
        <span>{number}</span>
        <div className="img-div">
          <img src={img} alt="mobile" />
          <div style={{ backgroundColor: color }}></div>
        </div>
      </div>
      <div className="bottom flex colm">
        <h4>{title}</h4>
        <p>{secondTitle}</p>
      </div>
    </div>
  );
};
